import React from "react";
import {useSelector} from "react-redux";


const ThemeProvider = ({ children }) => {
    const lightThemeEnabled = useSelector((state) => state.theme.lightThemeEnabled);
    return (
        <div className={"app-bg1"} data-theme={lightThemeEnabled ? "light" : "dark" }>
            <div className={"app-bg2"}>
                {children}
            </div>
        </div>
    );
};

export default ThemeProvider;