import React, {useEffect, useRef, useState} from "react";
import nftrade from "../../assets/logo/nftrade.png";
import kalao from "../../assets/logo/kalao.png";
import joepegs from "../../assets/logo/joepegs-logo.png";
import Carousel from "../../components/Carousel";
import openMenu from "../../components/openMenu";

function HeroSection() {
    const ref = useRef();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    openMenu(ref, isMenuOpen, setIsMenuOpen)

  return (

    <div className={"section"} id="home">
        <div className={"card-gl"} id={"home-card"}>
            <div className={"title"}>
            </div>
            <div className={"content"}>
                <div>
                    <h2 id={"cwp"}>Change the world<br/>perception</h2>
                    <p>Enhance creativity with artificial intelligence <br/>
                        Design code and make art
                    </p>
                    <div className={"nft-col"} ref={ref}>
                        <a className={"button"} onClick={() => setIsMenuOpen((oldState) => !oldState)}>
                            Explore Collection
                        </a>
                        <ul className={isMenuOpen ? "menu open" : "menu"}>
                            <li>
                                <a href="https://marketplace.kalao.io/collection/0xa44a3858fd36b6daabdaff7bc493d2cf4634149c" target="_blank" rel="noopener noreferrer">
                                    <img src={kalao} alt="kalao" />
                                    Kalao
                                </a>
                            </li>
                            <li>
                                <a href="https://joepegs.com/collections/haga" target="_blank" rel="noopener noreferrer">
                                    <img src={joepegs} alt="joepegs" />
                                    Joepegs
                                </a>
                            </li>
                            <li>
                                <a href="https://nftrade.com/assets/avalanche/0xa44a3858fd36b6daabdaff7bc493d2cf4634149c" target="_blank" rel="noopener noreferrer">
                                    <img src={nftrade} alt="nftrade" />
                                    NFTrade
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={"card-gl"} id={"carousel"}>
                    <Carousel/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default HeroSection;