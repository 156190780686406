import React from "react";
import './css/index.css'
import Home from "./Pages/Home";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Mint from "./Pages/Mint";


function App() {

  return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path="/mint" element={<Mint />} />
                <Route
                    path="*"
                    element={<Navigate to="/" replace />}
                />
            </Routes>
        </BrowserRouter>
  );
}

export default App;