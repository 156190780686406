import React from 'react';

import carousel0Desktop from '../assets/carousel/0.gif';
import carousel1Desktop from '../assets/carousel/1.gif';
import carousel2Desktop from '../assets/carousel/2.gif';
import carousel3Desktop from '../assets/carousel/3.gif' ;

import carousel0Mobile from '../assets/carousel/0.png';
import carousel1Mobile from '../assets/carousel/1.png';
import carousel2Mobile from '../assets/carousel/2.png';
import carousel3Mobile from '../assets/carousel/3.png' ;



const delay = 5000;

const Carousel = () => {
    let carousel0;
    let carousel1;
    let carousel2;
    let carousel3;
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    if (window.innerWidth > 800){
        carousel0 = carousel0Desktop;
        carousel1 = carousel1Desktop;
        carousel2 = carousel2Desktop;
        carousel3 = carousel3Desktop;
    }
    else{
        carousel0 = carousel0Mobile;
        carousel1 = carousel1Mobile;
        carousel2 = carousel2Mobile;
        carousel3 = carousel3Mobile;
    }

    const content = [{
        image : carousel0,
        text : "Flowers whisper greeting breezes and funereal wreaths ..."
    },
        {
            image : carousel1,
            text: "My identity has books a mother and two arms and leg sand two arms and legs"
        },
        {
            image : carousel2,
            text: "Down south seas are filled with flying fishes"
        },
        {
            image : carousel3,
            text: "Death solved in the society of death"
        }];


    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === content.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <div>
            <div className="slideshow">
                <div
                    className="slideshowSlider"
                    style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                    {content.map((val, index) => (
                        <div className="slide" key={index}>
                            <img src={val.image}/>
                            <div className={"text-container"}>
                                <div className={"center"}>
                                    <p>{val.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="slideshowDots">
                    {content.map((_, idx) => (
                        <div
                            key={idx}
                            className={`slideshowDot${index === idx ? " active" : ""}`}
                            onClick={() => {
                                setIndex(idx);
                            }}
                        ></div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default Carousel;