import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {TOGGLE_LIGHTTHEME} from "../redux/theme/themeActions";
import light from "../assets/icon/light_mode.svg"
import dark from "../assets/icon/dark_mode.svg"
import darkEmpty from "../assets/icon/dark_mode_empty.svg"
import lightEmpty from "../assets/icon/light_mode_empty.svg"

const themeToggle = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const lightThemeEnabled = useSelector((state) => state.theme.lightThemeEnabled);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fill, setFill] = useState(false);

   const changeTheme = () => {
       dispatch({ type: TOGGLE_LIGHTTHEME });
    }
    const changeIcon = () => {
        setFill(!fill)
    }


    return (
        <div
            className={"theme-toggle"}
            onClick={() => changeTheme()}
            onMouseOver={() => changeIcon()}
            onMouseLeave={() => changeIcon()}
        >
            {lightThemeEnabled ?
                (fill ? <img src={dark} alt={"dark theme"}/> : <img src={darkEmpty} alt={"dark theme empty"}/>):
                (fill ? <img src={light} alt={"light theme"}/> : <img src={lightEmpty} alt={"light theme empty"}/>) }
        </div>

    );
};

export default themeToggle;