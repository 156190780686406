import * as React from 'react';
import Twitter from "../../assets/icon/twitter-square-brands.svg";
import Instagram from "../../assets/icon/instagram-square-brands.svg";
import Mail from "../../assets/icon/envelope-open-solid.svg"
import Discord from "../../assets/icon/discord.svg"


const Footer = () => {

  return (
    <div className={"footer"}>
      <div className={"social"}>
          <a href="mailto:contact@hagaverse.com" target="_blank" rel="noopener noreferrer">
              <img src={Mail} alt="Mail" id={"email"}/>
          </a>
        <a href="https://twitter.com/hagaverse" target="_blank" rel="noopener noreferrer">
          <img src={Twitter} alt="Twitter" />
        </a>
        <a href="https://www.instagram.com/hagaverse" target="_blank" rel="noopener noreferrer">
          <img src={Instagram} alt="Instagram" />
        </a>
      </div>
        <div className={"credit"}>
            <h4>© 2022 HagaVerse</h4>
            <p>Enhance creativity. Upgrade your workflow.</p>
        </div>
    </div>
  );
};

export default Footer;
