import * as actions from "./themeActions";

const themeReducer = (state = { lightThemeEnabled: false }, action) => {
    switch (action.type) {
        case actions.TOGGLE_LIGHTTHEME:
            return { ...state, lightThemeEnabled: !state.lightThemeEnabled };

        default:
            return state;
    }
};

export default themeReducer;