import { useDispatch} from "react-redux";
import ThemeToggle from "../../components/ThemeToggle";
import arrowEmpty from "../../assets/icon/arrow_circle_empty.svg"

import carousel1Desktop from '../../assets/carousel/1.gif';
import carousel2Desktop from '../../assets/carousel/2.gif';
import carousel3Desktop from '../../assets/carousel/3.gif';

import carousel1Mobile from '../../assets/carousel/1.png';
import carousel2Mobile from '../../assets/carousel/2.png';
import carousel3Mobile from '../../assets/carousel/3.png';


function MintSection() {
  let img0;
  let img1;
  let img2;
  if (window.innerWidth > 800){
    img0 = carousel1Desktop;
    img1 = carousel2Desktop;
    img2 = carousel3Desktop;
  }
  else{
    img0 = carousel1Mobile;
    img1 = carousel2Mobile;
    img2 = carousel3Mobile;
  }

  const dispatch = useDispatch();


  return (
    <div id="mint">
      <div className={"mint-header"}>
        <a href={"/"}><img src={arrowEmpty} alt={"back"}/></a>
        <h1>Mint</h1>
        <ThemeToggle/>
      </div>



      <div className={"mint-body"}>
        <div className={"mint-img"}>
          <img src={img0} alt={"image 0"}/>
          <img src={img1} alt={"image 1"}/>
          <img src={img2} alt={"image 2"}/>
        </div>


          <div className={"card-gl"}>
            <h1>
              Mint available soon
            </h1>
          </div>

      </div>



      <div className={"mint-footer"}>
        <h3>Easy steps</h3>
        <h2>How to mint</h2>
        <div className={"step"}>
          <p className={"nbr"}>1</p>
          <p>Connect Your Wallet</p>
        </div>
        <div className={"step"}>
          <p className={"nbr"}>2</p>
          <p>Select Your Quantity</p>
        </div>
        <div className={"step"}>
          <p className={"nbr"}>3</p>
          <p>Confirm the Transaction</p>
        </div>
        <div className={"step"}>
          <p className={"nbr"}>4</p>
          <p>Receive Your NFTs</p>
        </div>
      </div>


    </div>
  );
}

export default MintSection;
