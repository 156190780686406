import React, {useEffect, useRef, useState} from "react";
import arrowUp from "../../assets/icon/arrow-up.svg"
import openMenu from "../../components/openMenu";
import Twitter from "../../assets/icon/twitter-square-brands.svg";
import Discord from "../../assets/icon/discord.svg"
import ThemeToggle from "../ThemeToggle";
import Medium from "../../assets/logo/medium_logo.svg"
import Whitepaper from "../../assets/logo/Whitepaper_logo.svg"

let defaultY = 0;

const HeaderBar = () => {
  const [state, setstate] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hMenu, setHMenu] = useState(false);
  const head = useRef();
  const ref = useRef();

  openMenu(ref, isMenuOpen, setIsMenuOpen)


  const getPosition = () => {
    const y = head.current.offsetTop;
    const scrollValue = document.documentElement.scrollTop;
      if (scrollValue < window.innerHeight){
          defaultY = y
      }

    if (window.innerWidth > 800){

        if (scrollValue > 2 * defaultY && scrollValue > 10){
            document.getElementById("postit").style.opacity = "1";
        }
        else {
            document.getElementById("postit").style.opacity = "0";
        }
        if (scrollValue >= window.innerHeight){
            document.getElementById("home").classList.remove("scroll");
            document.getElementById("landing").classList.remove("scroll");
        }
        else {
            document.getElementById("home").classList.add("scroll");
            document.getElementById("landing").classList.add("scroll");
        }
    }

    if(y > defaultY + 10 && scrollValue > 10)
    {
      setstate(true);
    }
    else{
      setstate(false);
    }
  };

  useEffect(() => {
    getPosition();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getPosition);
    window.addEventListener('scroll', getPosition);

  }, []);


    if(hMenu){
        document.body.style.overflow = "hidden"
    }
    else {
        document.body.style.overflow = "unset"
    }

  return (
    <div ref={head} id={"header"} className={state ?"header-bg":""}>
        <div className={"header-content"}>
            <a href="/#landing">
                <h1>Haga</h1>
            </a>
            <nav>
                <a className={"link"} href="/mint">
                    Mint
                </a>
                <div className={"drop-down link"} ref={ref}>
                    <span onClick={() => setIsMenuOpen((oldState) => !oldState)}>Docs</span>
                    <ul className={isMenuOpen ?  "menu open" : "menu"}>
                        <li><a href="https://twitter.com/hagaverse" target="_blank" rel="noopener noreferrer"><img src={Medium} alt={"Medium"}/>Medium</a></li>
                        <li><a href="https://twitter.com/hagaverse" target="_blank" rel="noopener noreferrer"><img src={Whitepaper} alt={"Whitepaper"}/>Whitepaper</a></li>
                    </ul>
                </div>
                <div className={"social"}>
                    <a href="https://twitter.com/hagaverse" target="_blank" rel="noopener noreferrer">
                        <img src={Twitter} alt="Twitter" />
                    </a>
                </div>

            </nav>
            <div className="hamburger-menu">
                <label id={ hMenu ? "menu__toggle": ""} className={"menu__btn"} onClick={() => setHMenu(!hMenu)}>
                    <span/>
                </label>
                <ul className="menu__box">
                    <li><a className="menu__item" id={"mint-link"} href="/mint" onClick={() => setHMenu(!hMenu)}>Mint</a></li>
                    <li><a className="menu__item" href="https://twitter.com/hagaverse" target="_blank" rel="noopener noreferrer">Medium</a></li>
                    <li><a className="menu__item" href="https://twitter.com/hagaverse" target="_blank" rel="noopener noreferrer">Whitepaper</a></li>
                    <li>
                        <div className={"social"}>
                            <a href="https://twitter.com/hagaverse" target="_blank" rel="noopener noreferrer">
                                <img src={Twitter} alt="Twitter" />
                            </a>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
        <ThemeToggle/>
        <a href={"/#home"}>
            <div id={"postit"}>
                <img src={arrowUp}/>
            </div>
        </a>
    </div>
  );
};

export default HeaderBar;
