import React, {useState} from "react";

const question1 = [
    { id: 1, title: "What is HAGA collection ?", content: "A collaboration between two state of the art AI. One algorithm has been taught to dive into human soul and write poems about it. The other algorithm is visually expressing his brother's ideas with looped animations." },
    { id: 2, title: "Are there different rarity levels ?", content: "No, each piece is unique. You will be the judging them on what you read, the poems, and see, the GIFs." },
    { id: 3, title: "What is the mint price ?", content: "We have concluded to a fair price of 2.5 AVAX." },
    { id: 4, title: "What is the maximum supply ?", content: "There will only be 1,111 pieces." }
];

const question2 = [
    { id: 5, title: "Where can I buy an HAGA NFT ?", content: "Minting will take place on our website." },
    { id: 6, title: "Which network ?", content: "This artwork is stored as ERC-721 tokens on the Avalanche blockchain with metadata and images hosted on IPFS." },
    { id: 7, title: "When is the launch date ?", content: "To be announced." },
    { id: 8, title: "How can I have access to HAGA news and updates ?", content: "On Medium, Twitter and Discord (coming soon)." }
];


function Faq() {

  const [open, setOpen] = useState('');

  const clickActive = (id) => (e) => {
    e.preventDefault();
    setOpen(id !== open ? id : "");
  };

  return (
    <div className={"section"} id="faq">
      <h1>FAQ</h1>
        <div className={"underline"}/>
        <div className={"faq-content"}>
          <div className="container">
              {question1?.map((val, index) => (
                  <details
                      key={val.id}
                      open={open === val.id}
                      onClick={clickActive(val.id)}
                  >
                      <summary>{val.title}</summary>
                      <div>{val.content}</div>
                  </details>
              ))}
          </div>
          <div className="container">
              {question2?.map((val, index) => (
                  <details
                      key={val.id}
                      open={open === val.id}
                      onClick={clickActive(val.id)}
                  >
                      <summary>{val.title}</summary>
                      <div>{val.content}</div>
                  </details>
              ))}
          </div>
      </div>

    </div>
  );
}

export default Faq;