import {createStore, combineReducers } from "redux";
import themeReducer from "./theme/themeReducer";

const rootReducer = combineReducers({
  theme: themeReducer
});



let initialState = {};

const configureStore = () => {
  return createStore(rootReducer, initialState);
};

const store = configureStore();


export default store;
