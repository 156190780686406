//This is home page, It will contains all the sections require in this page.
import * as React from 'react';
import HeroSection from "../Sections/Hero";
import {lazy, Suspense, useState} from "react";
import HeaderBar from "../components/Header";
import Footer from "../components/Footer";
import Landing from "../Sections/Landing";
import Faq from "../Sections/Faq";



const About = lazy(() => import("../Sections/About"));

const Home = () => {
  return (
      <>
          <Suspense fallback={null}>
            <Landing/>
            <HeaderBar />
            <HeroSection />
            <About />
            <Faq/>
            <Footer />
          </Suspense>
      </>

  );
};

export default Home;

