import React, {useEffect} from "react";
import '../css/index.css'
import arrow from '../assets/icon/arrow-down.svg'
import video from '../assets/video/project_nebula.mp4'
import image from '../assets/image/landing.png'

function Landing() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const [bg, setBg] = React.useState(false);
    let count = 0;

    useEffect(() =>{
        if(window.innerWidth > 800){
            setBg(true)
        }
        else {
            setBg(false)
        }
        }, []);


    function getElementAnn(id, val){
        document.getElementById(id).style.animation= val;
    }

    function getElementDis(id, val){
        document.getElementById(id).style.display = val;
    }

    async function glitchLoop() {
        while (true){
            if (count === 0){
                await delay(2500);
                getElementDis("reveal", "none")
            }
            else {
                await delay(1000);
            }
            getElementDis("g1", "block");
            getElementAnn("g1", "glitch 2s infinite");
            getElementDis("g2", "block");
            getElementDis("g3", "block");
            await delay(2000);
            getElementAnn("g1", "none")
            getElementDis("g2", "none");
            getElementDis("g3", "none");
            count = 1;
            if (count === 1){
                document.getElementById("arrow").style.visibility = "visible";
            }
            await delay(2000);
        }
    }

    useEffect(() => {
        glitchLoop()
    })



  return (
    <div id="landing">
        {bg ? (
            <video autoPlay muted loop id="video">
                <source src={video} type="video/mp4"/>
            </video>
        ) :(
            <img src={image} alt={"landing image"}/>
        )}

        <p id={"reveal"}>
            <span>H</span>
            <span>A</span>
            <span>G</span>
            <span>A</span>
        </p>
        <p className={"glitch"} id={"g1"}>
            <span aria-hidden={"true"} id={"g2"}>Haga</span>
            Haga
            <span aria-hidden={"true"} id={"g3"}>Haga</span>
        </p>
        {bg ? (
            <a href={"#home"}>
                <img alt={arrow} src={arrow} id={"arrow"}/>
            </a>
        ) :(
            <></>
        )}
    </div>
  );
}

export default Landing;