import React from 'react';
import HeaderBar from "../components/Header";
import MintSection from "../Sections/Mint";

const Mint = () => {
    return (
        <>
            <MintSection/>
        </>
    );
};

export default Mint;